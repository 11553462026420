<nz-layout class="app-layout">
  <app-home *ngIf="isAtRoot(); else appView"></app-home>
  <ng-template #appView>
    <ukata-loading></ukata-loading>
    <nz-sider
      [(nzCollapsed)]="isCollapsed"
      [nzCollapsedWidth]="0"
      [nzTrigger]="null"
      class="menu-sidebar"
      id="app-sidebar"
      nzBreakpoint="md"
      nzCollapsible
      nzWidth="256px"
    >
      <div class="sidebar-logo">
        <img alt="Ukata icon" src="assets/icons/icon-72x72.png" />
      </div>
      <ul
        [nzInlineCollapsed]="isCollapsed"
        nz-menu
        nzMode="inline"
        nzTheme="dark"
      >
        <li nz-submenu nzOpen nzTitle="Dashboard">
          <ul>
            <li (click)="hideMenu()" nz-menu-item nzMatchRouter>
              <a routerLink="user/dashboard">Dashboard</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/learner/quizzes">Quizzes</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/learner/courses">Courses</a>
            </li>
          </ul>
        </li>
        <li *ngIf="isMaker" nz-submenu nzOpen nzTitle="Maker">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/maker/quizzes"
                >Create quizzes</a
              >
            </li>
            <!--          <li nz-menu-item nzMatchRouter>-->
            <!--            <a routerLink="/maker/blogs">Create blogs</a>-->
            <!--          </li>-->
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/maker/courses">{{
                "maker.courses.create-course" | translate
              }}</a>
            </li>
            <!--          <li nz-menu-item nzMatchRouter>-->
            <!--            <a routerLink="/maker/vocabulary">Create vocabulary</a>-->
            <!--          </li>-->
            <!--            -->
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/maker/studio"
                >Maker studio</a
              >
            </li>
          </ul>
        </li>
        <li *ngIf="isAdmin" nz-submenu nzOpen nzTitle="Admin">
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/admin/dashboard"
                >Admin dashboard</a
              >
            </li>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/admin/tools">Admin tools</a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a (click)="hideMenu()" routerLink="/admin/user-attempts"
                >User attempts</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nz-sider>
    <nz-layout [style.margin-left.px]="isCollapsed ? 0 : 256" id="main-app">
      <nz-header>
        <div class="app-header">
          <span (click)="isCollapsed = !isCollapsed" class="header-trigger">
            <span class="material-symbols-outlined">
              <span *ngIf="!isCollapsed">close</span>
              <span *ngIf="isCollapsed">menu</span>
            </span>
          </span>
          <div class="right-menu">
            <mat-icon
              [nz-tooltip]="'Contact us'"
              color="primary"
              fontSet="material-symbols-outlined"
              routerLink="/public/contact"
              >mail
            </mat-icon>

            <mat-icon
              (click)="openMakerTools()"
              [nz-tooltip]="'Maker tools'"
              color="primary"
              fontSet="material-symbols-outlined"
              >construction
            </mat-icon>
            <mat-icon
              color="primary"
              fontSet="material-symbols-outlined"
              routerLink="/user/profile"
              >account_circle
            </mat-icon>
          </div>
        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <router-outlet></router-outlet>
        </div>
      </nz-content>
    </nz-layout>
  </ng-template>
</nz-layout>
