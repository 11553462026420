import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ukata-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpErrorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
