import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { User } from './shared/interfaces/user';
import { CommonService } from './core/services/common.service';
import { Router } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { CurrentUserService } from './core/services/current-user.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './core/services/local-storage.service';
import { MakerToolsComponent } from './maker/components/maker-tools/maker-tools.component';
import { Subscription } from './shared/interfaces/subscription';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ukata-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  isAtRoot(): boolean {
    return this.router.url === '/';
  }

  title = 'Ukata';
  isCollapsed = false;
  displayNavbar: string | undefined;
  @ViewChild('sidenav') sidenav: { toggle: () => void } | undefined;

  user: User | undefined;
  showSidebar = true;
  isLoggedin = false;
  isMaker = false;
  isAdmin = false;
  isMobile = false;
  subscription: Subscription[] = [];
  hasActiveSubscription = true;
  protected readonly CommonService = CommonService;

  constructor(
    private router: Router,
    public authService: AuthGuard,
    private currentUserService: CurrentUserService,
    public translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private modalServide: NzModalService,
  ) {
    this.isLoggedin = this.authService.isAuthenticated() || false;
    this.isMaker = this.authService.isMaker();
    this.isAdmin = this.authService.isAdmin();
    this.isMobile =
      ('ontouchstart' in window || navigator.maxTouchPoints > 0) &&
      window.innerWidth <= 800;

    this.fetchSubscription();
    const savedLang =
      this.localStorageService.getScalarItem('language') ?? 'en';
    //strip the quotes from savedLang
    translateService.setDefaultLang(savedLang);
    translateService.use(savedLang);
  }

  ngAfterViewInit() {
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener('click', (event) => {
      this.showSidebar = true;
    });

    //call to get user details if loggedin
    if (this.authService.isAuthenticated()) {
      this.currentUserService.getUserDetails().subscribe({
        next: (userDetails) => {
          this.user = userDetails;
        },
      });
    }
  }

  ngOnInit() {
    this.displayNavbar = '1';
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/']).then((r) => {
      console.log('r', r);
    });
  }

  openMakerTools() {
    this.modalServide.create({
      nzContent: MakerToolsComponent,
      nzWidth: '80%',
    });
  }

  private fetchSubscription() {
    if (this.authService.isAuthenticated()) {
      this.currentUserService.getSubscriptions().subscribe({
        next: (subscriptions) => {
          this.subscription = subscriptions;
          this.hasActiveSubscription = subscriptions.some((subscription) => {
            return (
              subscription.status &&
              subscription.status.toLowerCase() === 'active'
            );
          });
        },
      });
    }
  }

  hideMenu() {
    if (this.isMobile) {
      this.isCollapsed = true;
    }
  }
}
