import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { LoadingService } from '../../core/services/loading.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private isCallingApi: boolean = false;

  constructor(private loadingService: LoadingService) {
    this.loadingService.loading$.subscribe({
      next: (v) => {
        this.isCallingApi = v;
      },
      error: (err) => {
        this.isCallingApi = false;
      },
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isCallingApi) this.loadingService.on();
    return next.handle(req).pipe(finalize(() => this.loadingService.off()));
  }
}
